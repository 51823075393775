import * as React from "react";
import { useEffect } from "react";
import { Link } from "gatsby";

// markup
export default function NotFoundPage() {
  useEffect(() => {
    import("@nielsen-media/gds-web");
  }, []);
  return (
    <main style={{ padding: "96px 48px" }}>
      <title>Page Not found</title>
      <div className="empty-state">
        <div class="icon-wrapper">
          <gds-display-icon
            size="64"
            icon="warning-alert"
            foreground="orange"
            foreground-level="700"
          ></gds-display-icon>
        </div>
        <div className="text">
          Page Not Found
          <div className="secondary">
            Sorry, this page is inaccessible to you or does not exist.
          </div>
        </div>
        <Link to="/">
          <gds-button icon-position="left" icon="arrow-left" size="regular">
            Back to Home
          </gds-button>
        </Link>
      </div>
    </main>
  );
}
